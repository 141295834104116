import React, { FC, useEffect, useState } from 'react';
import { PageWrapper } from '../../../components';
import { Container } from '@linkeo.com/ui-lib-react';
import { BASE_QUESTION, useApi } from '../../../providers/api-provider';
import { ScopeType } from '../../../interface/scope.types';
import { useHistory, useParams } from 'react-router-dom';
import { QuestionType, QuestionTypes } from '../../../interface/question.types';
import { QuestionForm } from '../../../components/question/question.form';
import { getQuestionByType } from '../../../utils/question.utils';
import { routePresentation } from '../../../routes';

export const ScopeQuestionPage: FC = () => {
  const API = useApi();
  const history = useHistory();
  const {
    scope, questionType,
  } = useParams<{ scope: ScopeType, questionType: QuestionType | string }>();
  const [getQuestion, setQuestion] = useState<QuestionTypes>(BASE_QUESTION);
  const [getGlobalLoading, setGlobalLoading] = useState<boolean>(true);
  useEffect(() => {
    const newQuestion = getQuestionByType(questionType);
    if (newQuestion) {
      setQuestion(newQuestion);
      setGlobalLoading(false);
      return;
    }
    API.scopeQuestion(scope, questionType).then(result => {
      setQuestion(result);
    }).finally(() => {
      setGlobalLoading(false);
    });
  }, [API, questionType, scope]);
  const close = () => {
    history.push(routePresentation);
  };
  return <PageWrapper isLoading={getGlobalLoading}>
    <Container size={'lg'}>
      <QuestionForm
        question={getQuestion}
        loading={false}
        isLoading={false}
        setQuestion={() => {}}
        onSubmit={() => {
        }}
        onDeleteChoice={() => {
        }}
        onAddChoice={() => {
        }}
        onEditChoice={() => {
        }}
        onClose={close} />
    </Container>
  </PageWrapper>;
};

import React, { FC } from 'react';
import { ActivityForm } from '../../../components/activity/activity-form';
import { PageWrapper } from '../../../components';
import { Container } from '@linkeo.com/ui-lib-react';
import { Error404 } from '../../../components/commons/404-error';
import { useActivityStore } from '../../../providers/activity-provider';

export const ActivityPage: FC = () => {

  const {error, getGlobalLoading} = useActivityStore();

  return <PageWrapper isLoading={getGlobalLoading}>
    {!error ? <Container size={'lg'}>
      <ActivityForm />
    </Container> : <Error404 />}
  </PageWrapper>;
};
import React, { FC, FormEvent, useState } from 'react';
import { Activity } from '../../interface/activity.types';
import { UpdateItemInObject } from '../../utils/deep-object.utils';
import { QuestionDialog } from '../question/question.dialog';
import { Question, QuestionType } from '../../interface/question.types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useConfirm } from '../../providers/confirm-provider';
import { useIntl } from 'react-intl';
import { routeActivities } from '../../routes';
import {
  BaseButton,
  Box,
  Button,
  CardSubtitle,
  CardTitle,
  Col,
  DraggableList,
  DraggableListItem,
  ImageValue, InOut,
  Input,
  InputImage,
  InputNumber, LabelWrapper,
  Row,
  SpanLink, Switch, useToaster,
} from '@linkeo.com/ui-lib-react';
import { TextEditor } from '../commons/text-editor';
import { useActivityStore } from '../../providers/activity-provider';

export const ActivityForm: FC = () => {
  const { activity, updateActivity, saveActivity, questions, updateQuestions, setEditImage, image, deleteQuestion } = useActivityStore();
  const history = useHistory();
  const match = useRouteMatch();
  const intl = useIntl();
  const toast = useToaster();
  const { openConfirm } = useConfirm();
  const theme = useTheme();
  const [getImageError, setImageError] = useState<boolean>(false);
  const [getShowDialogAddQuestion, setShowDialogAddQuestion] = useState<boolean>(false);
  const [getInvalids, setInvalids] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const isInvalidForm = (): boolean => {
    const invalids = [];
    if (!activity.name) {
      invalids.push('name');
    }
    if (activity.minPrice && activity.maxPrice) {
      if (activity.minPrice > activity.maxPrice) {
        invalids.push('maxPrice', 'minPrice');
      }
    }
    setInvalids(invalids);
    return !!invalids.length;
  };

  const onSubmitForm = async (event: FormEvent) => {
    event.preventDefault();
    if (isInvalidForm()) {
      return;
    }
    setLoading(true)
    try {
      await sendActivityChange();
      onClose();
    } catch (e) {
      console.error(e);
      toast(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' }));
      setLoading(false);
    }
  };

  const onClose = () => {
    history.push(routeActivities);
  };

  const gotoQuestion = (questionType: QuestionType | string) => {
    history.push(`${match.url}/question/${questionType}`);
  };

  const sendActivityChange = async (): Promise<Activity> => {
    return await saveActivity(activity.id.startsWith('NEW'))
  };

  const changeImage = (image: ImageValue) => {
    setImageError(false);
    setEditImage({
      isNew: true,
      image,
    });
  };

  const requestEditDraggedQuestion = (dragged: DraggableListItem<Question>) => {
    gotoQuestion(dragged.data.id);
  };

  const requestAddQuestion = () => {
    if (isInvalidForm()) {
      return;
    }
    setShowDialogAddQuestion(true);
  };

  const addQuestion = (type: QuestionType) => {
    setShowDialogAddQuestion(false);
    gotoQuestion(type)
  };

  const removeQuestion = async (question: Question) => {
    return new Promise(resolve => {
      openConfirm({
        title: '',
        content: intl.formatMessage({
            id: 'confirmDelete',
            defaultMessage: 'êtes-vous sûr de vouloir supprimer {name}',
          },
          { name: question.label }),
      }).then(async () => {
        updateQuestions(questions.filter(el => el.id !== question.id));
        if (activity.questions.findIndex(el => el.id === question.id) === -1) {
          await deleteQuestion(question)
        }
      }).catch(() => {
        resolve(activity.questions);
      });
    });
  };

  const updateDraggedQuestion = (draggedList: DraggableListItem<Question>[]) => {
    updateQuestions(draggedList.map(d => d.data));
  };

  return <Box onClose={onClose}
              title={activity.id.startsWith('NEW') ? intl.formatMessage({
                id: 'addAnActivity',
                defaultMessage: 'Ajouter une activité',
              }) : activity.name}>
    <form onSubmit={onSubmitForm}>
      <div style={{ padding: '20px 40px 40px' }}>
        <Row>
          <Col columns={[12, 6]}>
            <Input
              required
              label={intl.formatMessage({ id: 'actFormNameLabel', defaultMessage: 'Intitulé de l’activité' })}
              hasError={getInvalids.includes('name')}
              value={activity.name}
              onChange={ev => updateActivity(UpdateItemInObject(activity, 'name', ev))}
            />
          </Col>
          <Col columns={[12, 6]}>
            <InputImage
              label={intl.formatMessage({ id: 'actFormImageLabel', defaultMessage: 'Image d’illustration' })}
              value={image}
              addImageLabel={intl.formatMessage({ id: 'addImage', defaultMessage: 'Ajouter une image' })}
              replaceImageLabel={intl.formatMessage({ id: 'replaceImage', defaultMessage: 'Remplacer l’image' })}
              removeImageLabel={intl.formatMessage({ id: 'removeImage', defaultMessage: 'Supprimer l’image' })}
              onError={() => setImageError(true)}
              caption={getImageError ? intl.formatMessage({
                id: 'fieldLogoSizeError',
                defaultMessage: 'Accepte les images jpg,png de 2mb max',
              }) : ''}
              hasError={getImageError}
              onChange={changeImage}
            />
          </Col>
        </Row>
        <Row>
          <Col columns={[12]}>
            <Input
              label={intl.formatMessage({ id: 'actFormDescLabel', defaultMessage: 'Description' })}
              caption={intl.formatMessage({
                id: 'actFormDescCaption',
                defaultMessage: 'Ajoutez une description pour préciser votre activité',
              })}
              value={activity.description || ''}
              onChange={ev => updateActivity(UpdateItemInObject(activity, 'description', ev))}
            />
          </Col>
        </Row>
        <CardTitle>
          {intl.formatMessage({ id: 'actFormTarifTitle', defaultMessage: 'Valeur de base de l’activité' })}
        </CardTitle>
        <CardSubtitle>
          {intl.formatMessage({
            id: 'actFormTarifSubtitle',
            defaultMessage: 'Précisez la valeur de base de votre activité en communiquant des chiffres moyens (évitez les extrèmes).',
          })}
        </CardSubtitle>
        <Row>
          <Col columns={[12, 6]}>
            <InputNumber
              label={intl.formatMessage({ id: 'actFormPriceMinLabel', defaultMessage: 'Valeur minimum de l’activité' })}
              hasError={getInvalids.includes('minPrice')}
              min={0}
              step='any'
              value={activity.minPrice}
              onChange={ev => updateActivity(UpdateItemInObject(activity, 'minPrice', ev))}
            />
          </Col>
          <Col columns={[12, 6]}>
            <InputNumber
              label={intl.formatMessage({ id: 'actFormPriceMaxLabel', defaultMessage: 'Valeur maximum de l’activité' })}
              hasError={getInvalids.includes('maxPrice')}
              min={0}
              step='any'
              value={activity.maxPrice}
              onChange={ev => updateActivity(UpdateItemInObject(activity, 'maxPrice', ev))}
            />
          </Col>
        </Row>
        <Row style={{ padding: '24px 0' }}>
          <Col columns={[12]}>
            <Switch value={!!activity.eligibility}
                    onChange={(eligibility) => updateActivity({ ...activity, eligibility })}
                    onLabel={intl.formatMessage({
                      id: 'actFormEligibilityLabel',
                      defaultMessage: 'Configurer les réponses d’éligibilité',
                    })}
                    caption={intl.formatMessage({
                      id: 'actFormEligibilityCaption',
                      defaultMessage: 'Activez cette option pour indiquer si le client est éligible ou non et rédiger des messages personnalisés en conséquence.',
                    })} />
            <InOut show={!!activity.eligibility}>
              <LabelWrapper label={intl.formatMessage({
                id: 'actFormEligibilityTextLabel',
                defaultMessage: 'Message pour client {text}',
              }, { text: <strong>{intl.formatMessage({ id: 'eligibility', defaultMessage: 'éligible' })}</strong> })}>
                <TextEditor locale={intl.locale} value={activity.eligibilityCustomMessage || ''}
                            onChange={(eligibilityCustomMessage) => updateActivity({
                              ...activity,
                              eligibilityCustomMessage,
                            })} />
              </LabelWrapper>
              <LabelWrapper label={intl.formatMessage({
                id: 'actFormEligibilityTextLabel',
                defaultMessage: 'Message pour client {text}',
              }, { text: <strong>{intl.formatMessage({ id: 'ineligibility', defaultMessage: 'non éligible' })}</strong> })}>
                <TextEditor locale={intl.locale} value={activity.ineligibilityCustomMessage || ''}
                            onChange={(ineligibilityCustomMessage) => updateActivity({
                              ...activity,
                              ineligibilityCustomMessage,
                            })} />
              </LabelWrapper>
            </InOut>
          </Col>
        </Row>
        <CardTitle>
          {intl.formatMessage({ id: 'actFormQuestionTitle', defaultMessage: 'Questions complémentaires' })}
        </CardTitle>
        <CardSubtitle>
          {intl.formatMessage({
            id: 'actFormQuestionSubtitle',
            defaultMessage: 'Certains paramètres peuvent faire varier l\'estimation de votre activité. Sollicitez ici des informations complémentaires à vos prospects pour obtenir des estimations plus précises et leur fournir un résultat plus juste.',
          })}
        </CardSubtitle>
        <DraggableList<Question>
          onChange={updateDraggedQuestion}
          onDelete={(deleted) => removeQuestion(deleted.data)}
          items={questions.map(q => ({
            data: q,
            id: q.id,
            title: q.label,
            htmlSubtitle: q.description,
            canRemove: true,
            displayModify: true,
          } as DraggableListItem<Question>))}
          onEdit={requestEditDraggedQuestion} />
        <div style={{ margin: '30px 0' }}>
          <BaseButton style={{ color: theme.colors.primary }}
                      onClick={requestAddQuestion}>
            +&nbsp;
            <SpanLink
              style={{ color: theme.colors.primary }}>{intl.formatMessage({
              id: 'addAQuestion',
              defaultMessage: 'Ajouter une question',
            })}</SpanLink>
          </BaseButton>
        </div>
        <Row justifyContent={['center']}>
          <Col>
            <Button colorType={'outline'} onClick={onClose}>
              {intl.formatMessage({ id: 'cancel', defaultMessage: 'Annuler' })}
            </Button>
          </Col>
          <Col>
            <Button colorType={'primary'} type={'submit'} loading={loading}>
              {intl.formatMessage({ id: 'save', defaultMessage: 'Enregistrer' })}
            </Button>
          </Col>
        </Row>
      </div>
    </form>
    <QuestionDialog
      show={getShowDialogAddQuestion}
      onClose={() => setShowDialogAddQuestion(false)}
      onValid={addQuestion} />
  </Box>;
};

import { Activity } from '../interface/activity.types';
import { createContext, Dispatch, FC, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { useApi } from './api-provider';
import { Question } from '../interface/question.types';
import { EditImage } from '../interface/api.types';
import { ImageValue } from '@linkeo.com/ui-lib-react';

const defaultActivity: Activity = {
  id: 'NEW',
  name: '',
  description: '',
  questions: [],
  minPrice: null,
  maxPrice: null,
  active: true,
  type: 'CUSTOMER',
};

interface ActivityContextProps {
  activity: Activity;
  fetchActivity: (categoryId: string, activityId: string) => Promise<void>;
  resetActivity: () => void;
  updateActivity: (update: Activity) => void;
  getGlobalLoading: boolean;
  error: boolean;
  saveActivity: (isNew: boolean) => Promise<Activity>;
  questions: Question[];
  updateQuestions: (update: Question[]) => void;
  setEditImage: Dispatch<SetStateAction<EditImage>>;
  image: ImageValue;
  deleteQuestion: (question: Question) => Promise<void>
}

const ActivityContext = createContext<ActivityContextProps>({
  activity: defaultActivity,
  fetchActivity: () => Promise.reject(new Error('Provider is not initialized')),
  resetActivity: () => {
  },
  updateActivity: () => {
  },
  getGlobalLoading: true,
  error: false,
  saveActivity: () => Promise.reject(new Error('Provider is not initialized')),
  questions: [],
  updateQuestions: () => {
  },
  setEditImage: () => {
  },
  image: { stringFormat: '' },
  deleteQuestion: () => Promise.reject(new Error('Provider not initialized'))
});

export const useActivityStore = () => useContext<ActivityContextProps>(ActivityContext);

interface ActivityStoreProps {
  categoryId: string;
  activityId: string;
}

export const ActivityStore: FC<ActivityStoreProps> = ({ children, activityId, categoryId }) => {
  const API = useApi();
  const [activity, setActivity] = useState<Activity>(defaultActivity);
  const [getGlobalLoading, setGlobalLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [getEditImage, setEditImage] = useState<EditImage>({
    isNew: false,
    image: { stringFormat: activity.picture?.absoluteUrl },
  });

  const fetchActivity = useCallback(async (categoryId: string, activityId: string) => {
    const response = await API.getActivity(categoryId, activityId);
    setActivity(response);
    setQuestions(response.questions);
    setEditImage({
      isNew: false,
      image: { stringFormat: response.picture?.absoluteUrl },
    });
  }, [API]);

  useEffect(() => {
    if (activityId.startsWith('NEW')) {
      setGlobalLoading(false);
      return;
    }
    fetchActivity(categoryId, activityId).catch(e => {
      console.error(e);
      setError(true);
    }).finally(() => {
      setGlobalLoading(false);
    });
    return () => {
      setActivity(defaultActivity);
      setError(false);
      setQuestions([]);
    };
  }, [categoryId, activityId, fetchActivity]);

  const resetActivity = useCallback(() => {
    setActivity(defaultActivity);
  }, []);

  const updateActivity = (update: Activity) => {
    setActivity(update);
  };

  const updateQuestions = (update: Question[]) => {
    setQuestions(update);
  };

  const saveActivity = async (isNew: boolean): Promise<Activity> => {
    let picture;
    if (getEditImage.isNew && getEditImage.image.binary) {
      picture = await API.postFile(getEditImage.image.binary, '/picture');
    }
    const act = {
      ...activity,
      maxPrice: activity.maxPrice || 0,
      minPrice: activity.minPrice || 0,
    };
    const deletedQuestions = activity.questions.filter(q => !questions.some(qs => qs.id === q.id));
    deletedQuestions.forEach(async (question) => await API.deleteQuestion(categoryId, activityId, question.id).catch(e => console.error(e)));
    let response;
    if (isNew) {
      response = await API.postActivity(categoryId, getEditImage.isNew ? { ...act, picture, questions } : { ...act, questions });
    } else {
      response = await API.putActivity(categoryId, getEditImage.isNew ? { ...act, picture, questions } : { ...act, questions });
    }
    setActivity(response);
    return response;
  };

  const deleteQuestion = async (question: Question) => {
    await API.deleteQuestion(categoryId, activityId, question.id)
  }

  return <ActivityContext.Provider value={{
    activity,
    fetchActivity,
    resetActivity,
    updateActivity,
    getGlobalLoading,
    error,
    saveActivity,
    questions,
    updateQuestions,
    setEditImage,
    image: getEditImage.image,
    deleteQuestion
  }}>
    {children}
  </ActivityContext.Provider>;
};


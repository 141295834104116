import { FC } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { ActivityStore } from '../../../providers/activity-provider';
import { routeCategory, routeQuestion } from '../../../routes';
import { ActivityPage } from './activity.page';
import { QuestionPage } from './question.page';

export const PageIndex: FC = () => {
    const { categoryId, activityId } = useParams<{ categoryId: string, activityId: string }>();

    return <ActivityStore activityId={activityId} categoryId={categoryId}>
        <Switch>
            <Route exact path={routeCategory} >
                <ActivityPage />
            </Route>
            <Route exact path={routeQuestion} >
                <QuestionPage />
            </Route>
        </Switch>
    </ActivityStore>
}
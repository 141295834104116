import { QuestionChoices, QuestionNumber, QuestionType, QuestionTypes } from '../interface/question.types';
import { BASE_QUESTION } from '../providers/api-provider';

export  const getQuestionByType = (questionType: QuestionType | string): QuestionTypes | null =>{
  switch (questionType) {
    case 'SHORT_TEXT':
    case 'LONG_TEXT':
      return {
        ...BASE_QUESTION,
        type: questionType,
      };
    case 'SINGLE_CHOICE':
    case 'MULTIPLE_CHOICE':
      return {
        ...BASE_QUESTION,
        type: questionType,
        choices: [],
        booleanFirst: false,
        addOverallQuantity: false,
        overallQuantityDescription: '',
        overallQuantityLabel: '',
        unit: '',
      } as QuestionChoices;
    case 'QUANTITY':
      return {
        ...BASE_QUESTION,
        type: questionType,
        unit: '',
      } as QuestionNumber;
    default:
      return null;
  }
}
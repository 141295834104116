import React, { Dispatch, FC, FormEvent, SetStateAction } from 'react';
import { QuestionText, QuestionTypes } from '../../interface/question.types';
import { Box } from '@linkeo.com/ui-lib-react';
import { QuestionCommon } from './question-common';
import { QuestionFooter } from './question-footer';
import { useIntl } from 'react-intl';

interface TextAreaFormProps {
  onSubmit: (value: QuestionText) => void;
  question: QuestionText;
  setQuestion: Dispatch<SetStateAction<QuestionTypes>>
  onClose: () => void;
  isLoading: boolean
}

export const TextAreaForm: FC<TextAreaFormProps> = props => {
  const { onClose, question, onSubmit, isLoading, setQuestion } = props;
  const intl = useIntl();
  const emitSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSubmit(question);
  };

  return <Box
      title={intl.formatMessage({id: 'questionFormTextareaTitle', defaultMessage: 'Question texte long'})}
      subtitle={intl.formatMessage({id: 'questionFormTextareaSubtitle', defaultMessage: 'Les prospects à qui vous poserez cette question pourront répondre par un texte  long{br}(plusieurs dizaine de mots)'}, {br: <br />}) as string}
      onClose={onClose}>
    <form onSubmit={emitSubmit} style={{ padding: '0 40px 40px 40px' }}>
      <QuestionCommon
          onChange={v => setQuestion((v as QuestionText))}
          value={question} />
      <QuestionFooter onCancel={onClose} isLoading={isLoading}/>
    </form>
  </Box>;
};

import React, { Dispatch, FC, SetStateAction } from 'react';
import {
    QuestionChoices,
    QuestionNumber,
    QuestionText,
    QuestionTypes,
} from '../../interface/question.types';
import {TextForm} from './text.form';
import {TextAreaForm} from './textarea.form';
import {NumberForm} from './number.form';
import {CheckboxForm} from './checkbox.form';
import {RadioForm} from './radio.form';
import {Choice} from '../../interface/choice.types';

interface QuestionFormProps {
    question: QuestionTypes;
    setQuestion: Dispatch<SetStateAction<QuestionTypes>>
    onSubmit: (question: QuestionTypes) => void;
    onDeleteChoice: (choice: Choice) => void;
    onAddChoice: () => void;
    onEditChoice: (choice: Choice) => void;
    onClose: () => void;
    loading: boolean;
    isLoading: boolean;
}

export const QuestionForm: FC<QuestionFormProps> = props => {
    const {question, onSubmit, onDeleteChoice, onClose, onEditChoice, onAddChoice, loading, isLoading, setQuestion} = props;
    switch (question.type) {
        default:
        case 'SHORT_TEXT':
            return <TextForm
                onClose={onClose}
                isLoading={isLoading}
                question={question as QuestionText}
                setQuestion={setQuestion}
                onSubmit={onSubmit}/>;
        case 'LONG_TEXT':
            return <TextAreaForm
                onClose={onClose}
                question={question as QuestionText}
                isLoading={isLoading}
                setQuestion={setQuestion}
                onSubmit={onSubmit}/>;
        case 'QUANTITY':
            return <NumberForm
                onClose={onClose}
                isLoading={isLoading}
                setQuestion={setQuestion}
                question={question as QuestionNumber}
                onSubmit={onSubmit}/>;
        case 'MULTIPLE_CHOICE':
        case 'SINGLE_CHOICE':
            return <>{question.type === 'MULTIPLE_CHOICE' ?
                <CheckboxForm
                    onDeleteChoice={onDeleteChoice}
                    onClose={onClose}
                    question={question as QuestionChoices}
                    setQuestion={setQuestion}
                    onSubmit={onSubmit}
                    onEditChoice={onEditChoice}
                    onAddChoice={onAddChoice}
                    loading={loading}
                    isLoading={isLoading}
                /> :
                <RadioForm
                    onDeleteChoice={onDeleteChoice}
                    onClose={onClose}
                    question={question as QuestionChoices}
                    setQuestion={setQuestion}
                    onSubmit={onSubmit}
                    onEditChoice={onEditChoice}
                    onAddChoice={onAddChoice}
                    loading={loading}
                    isLoading={isLoading}
                />}
            </>;
    }
};

import React, { Dispatch, FC, FormEvent, SetStateAction } from 'react';
import { QuestionCommon } from './question-common';
import { QuestionChoices, QuestionTypes } from '../../interface/question.types';
import {
  BaseButton,
  CardSubtitle,
  CardTitle,
  Checkbox,
  DraggableList,
  DraggableListItem, Flex,
  InOut,
  Input,
  LabelWrapper,
  SpanLink, Svg,
  Switch,
} from '@linkeo.com/ui-lib-react';
import { UpdateItemInObject } from '../../utils/deep-object.utils';
import { QuestionFooter } from './question-footer';
import { Choice } from '../../interface/choice.types';
import { useTheme } from 'styled-components';
import { useConfirm } from '../../providers/confirm-provider';
import { FormattedMessage, useIntl } from 'react-intl';

interface WithChoiceProps {
  onAddChoice: (preSaveQuestion: QuestionChoices) => void;
  onClose: () => void;
  onDeleteChoice: (choice: Choice) => void;
  onEditChoice: (choice: Choice) => void;
  onSubmit: (editQuestion: QuestionChoices) => void;
  question: QuestionChoices;
  setQuestion: Dispatch<SetStateAction<QuestionTypes>>
  loading: boolean;
  isLoading: boolean
}

export const WithChoice: FC<WithChoiceProps> = props => {
  const { onClose, onSubmit, question, onDeleteChoice, onEditChoice, onAddChoice, loading, isLoading, setQuestion } = props;
  const intl = useIntl();
  const theme = useTheme();
  const { openConfirm } = useConfirm();

  const isInvalidForm = (): boolean => {
    return false;
  };
  const emitSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (isInvalidForm()) {
      return;
    }
    onSubmit(question);
  };
  const submitAddChoice = () => {
    if (isInvalidForm()) {
      return;
    }
    onAddChoice(question);
  };
  const emitDeleteChoice = (draggedChoice: DraggableListItem<Choice>) => {
    openConfirm({
      content: intl.formatMessage({ id: 'confirmDelete', defaultMessage: 'êtes-vous sûr de vouloir supprimer {name}' },
        { name: draggedChoice.data.label }),
      title: '',
    }).then(() => {
      onDeleteChoice(draggedChoice.data);
    }).catch(() => {
    });
  };
  const reorderChoice = (list: DraggableListItem<Choice>[]) => {
    setQuestion({ ...question, choices: list.map(c => c.data) });
  };

  return <>
    <form onSubmit={emitSubmit} style={{ padding: '0 40px 40px 40px' }}>
      <QuestionCommon
        onChange={v => setQuestion({
          ...question,
          ...(v as QuestionChoices),
          booleanFirst: v.mandatory !== question.mandatory ? false : (v as QuestionChoices).booleanFirst,
        })}
        value={question} />
      {!question.mandatory && <InOut show={true} startTriggering><LabelWrapper
        caption={<FormattedMessage id={'questionFormPreConfirmCaption'}
                                   defaultMessage={'En cochant cette case, un bouton oui/non sera accolé à la question.{br}Si votre prospect répond “oui” à la question il pourra préciser son choix. S’il répond “non” il n’aura pas de choix à effectuer'}
                                   values={{ br: <br /> }} />}>
        <Checkbox
          value={question.booleanFirst}
          name={'booleanFirst'}
          onChange={e => setQuestion(UpdateItemInObject(question, 'booleanFirst', e))}>
          {intl.formatMessage({
            id: 'questionFormPreConfirmLabel',
            defaultMessage: 'Afficher les choix uniquement si la réponse à cette question est positive.',
          })}
        </Checkbox>
      </LabelWrapper></InOut>}
      <CardTitle>
        {intl.formatMessage({ id: 'questionFormChoicesTitle', defaultMessage: 'Choix de réponse' })}
      </CardTitle>
      <CardSubtitle>
        {intl.formatMessage({
          id: 'questionFormChoicesSubtitle',
          defaultMessage: 'Ajouter ci-dessous les choix de réponse qui seront proposés à vos prospects.',
        })}
      </CardSubtitle>
      {loading
        ? <Flex justifyContent={'center'}>
          <Svg animatedIcon={'spinner'} stroke={theme.colors.grey20} />
        </Flex>
        : <DraggableList<Choice>
          onChange={reorderChoice}
          onDelete={emitDeleteChoice}
          items={question.choices.map(c => ({
            data: c,
            id: c.id,
            title: c.label,
            subtitle: c.description || '',
            canRemove: true,
            displayModify: true,
          }))}
          onEdit={(d) => onEditChoice(d.data)}
        />}
      <div style={{ margin: '30px 0' }}>
        <BaseButton style={{ color: theme.colors.primary }} onClick={submitAddChoice}>
          +
          <SpanLink
            style={{ color: theme.colors.primary }}>{intl.formatMessage({
            id: 'addAChoice',
            defaultMessage: 'Ajouter un choix',
          })}</SpanLink>
        </BaseButton>
      </div>
      <hr />
      <Switch
        onLabel={intl.formatMessage({
          id: 'questionFormGlobalAmountLabel',
          defaultMessage: 'Demander au prospect de préciser une quantité globale pour cette question',
        })}
        onChange={e => setQuestion(UpdateItemInObject(question, 'addOverallQuantity', e))}
        value={question.addOverallQuantity}
      />
      <InOut show={question.addOverallQuantity} autoScroll>
        <CardSubtitle>
          <FormattedMessage id={'questionFormGlobalAmountSubtitle'}
                            defaultMessage={'Les prospects à qui vous poserez cette question devront répondre par une quantité.{br}La valeur des choix en cours sera multipliée par cette quantité pour fournir une estimation.'}
                            values={{ br: <br /> }} />
        </CardSubtitle>
        <Input
          required
          disabled={!question.addOverallQuantity}
          label={intl.formatMessage({ id: 'questionFormTitleLabel', defaultMessage: 'Intitulé de la question' })}
          caption={intl.formatMessage({
            id: 'questionFormGlobalAmountTitleCaption',
            defaultMessage: 'Posez une question en relation avec la quantité que vous voulez obtenir. Par exemple : De quelle surface avez-vous besoin ?',
          })}
          onChange={e => setQuestion(UpdateItemInObject(question, 'overallQuantityLabel', e))}
          value={question.overallQuantityLabel || ''}
        />
        <Input
          disabled={!question.addOverallQuantity}
          label={intl.formatMessage({ id: 'questionFormDescLabel', defaultMessage: 'Description' })}
          caption={intl.formatMessage({
            id: 'questionFormGlobalAmountDescCaption',
            defaultMessage: 'Ajoutez une description si nécessaire.',
          })}
          onChange={e => setQuestion(UpdateItemInObject(question, 'overallQuantityDescription', e))}
          value={question.overallQuantityDescription || ''}
        />
        <Input
          disabled={!question.addOverallQuantity}
          label={intl.formatMessage({
            id: 'questionFormNumberUnitLabel',
            defaultMessage: 'Unité à utiliser pour cette question de quantité',
          })}
          onChange={e => setQuestion(UpdateItemInObject(question, 'unit', e))}
          value={question.unit || ''}
        />
      </InOut>
      <QuestionFooter onCancel={onClose} isLoading={isLoading}/>
    </form>
  </>;
};


import React, { Dispatch, FC, FormEvent, SetStateAction } from 'react';
import { QuestionText, QuestionTypes } from '../../interface/question.types';
import { Box } from '@linkeo.com/ui-lib-react';
import { QuestionFooter } from './question-footer';
import { QuestionCommon } from './question-common';
import { useIntl } from 'react-intl';

interface TextFormProps {
    onSubmit: (value: QuestionText) => void;
    question: QuestionText;
    setQuestion: Dispatch<SetStateAction<QuestionTypes>>
    onClose: () => void;
    isLoading: boolean
}

export const TextForm: FC<TextFormProps> = props => {
    const { onClose, question, onSubmit, isLoading, setQuestion } = props;
    const intl = useIntl();
    const emitSubmit = (event: FormEvent) => {
        event.preventDefault();
        onSubmit(question);
    };

    return <Box
        title={intl.formatMessage({id: 'questionFormTextTitle', defaultMessage: 'Question texte court'})}
        subtitle={intl.formatMessage({id: 'questionFormTextSubtitle', defaultMessage: 'Les prospects à qui vous poserez cette question devront répondre par un texte court{br} (pas plus de deux ou trois mots)'}, {br: <br />}) as string}
        onClose={onClose}>
        <form onSubmit={emitSubmit} style={{ padding: '0 40px 40px 40px' }}>
            <QuestionCommon
                onChange={v => setQuestion(v as QuestionText)}
                value={question} />
            <QuestionFooter onCancel={onClose} isLoading={isLoading}/>
        </form>
    </Box>;
};

import React, { Dispatch, FC, SetStateAction } from 'react';
import { QuestionChoices, QuestionTypes } from '../../interface/question.types';
import { Box } from '@linkeo.com/ui-lib-react';
import { WithChoice } from './with-choice.form';
import { Choice } from '../../interface/choice.types';
import { useIntl } from 'react-intl';

interface CheckboxFormProps {
  onSubmit: (value: QuestionChoices, choiceId?: string) => void;
  question: QuestionChoices;
  setQuestion: Dispatch<SetStateAction<QuestionTypes>>
  onClose: () => void;
  onDeleteChoice: (choice: Choice) => void;
  onAddChoice: (preSaveQuestion: QuestionChoices) => void;
  onEditChoice: (choice: Choice) => void;
  loading: boolean;
  isLoading: boolean;
}

export const CheckboxForm: FC<CheckboxFormProps> = props => {
  const { onClose, question, onSubmit, onDeleteChoice, onEditChoice, onAddChoice, loading, isLoading, setQuestion } = props;
  const intl = useIntl();
  return <Box
    title={intl.formatMessage({ id: 'questionFormCheckboxTitle', defaultMessage: 'Question choix multiple' })}
    subtitle={intl.formatMessage({
      id: 'questionFormCheckboxSubtitle',
      defaultMessage: 'Les prospects à qui vous poserez cette question pourront sélectionner plusieurs des choix de réponse que vous leur proposerez.{br}Les choix effectués pourront avoir un impact sur le calcul de l’estimation finale.',
    }, { br: <br /> }) as string}
    onClose={onClose}>
    <WithChoice
      onDeleteChoice={onDeleteChoice}
      onSubmit={onSubmit}
      question={question}
      setQuestion={setQuestion}
      onClose={onClose}
      onAddChoice={onAddChoice}
      onEditChoice={onEditChoice}
      loading={loading}
      isLoading={isLoading}
    />
  </Box>;
};

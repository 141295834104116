import React, { Dispatch, FC, FormEvent, SetStateAction} from 'react';
import { QuestionNumber, QuestionTypes } from '../../interface/question.types';
import { Box, Input } from '@linkeo.com/ui-lib-react';
import { QuestionCommon } from './question-common';
import { QuestionFooter } from './question-footer';
import { useIntl } from 'react-intl';

interface NumberFormProps {
  onClose: () => void;
  onSubmit: (value: QuestionNumber) => void;
  question: QuestionNumber;
  setQuestion: Dispatch<SetStateAction<QuestionTypes>>
  isLoading: boolean
}

export const NumberForm: FC<NumberFormProps> = props => {
  const { onClose, question, onSubmit, isLoading, setQuestion } = props;
  const intl = useIntl();
  const emitSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSubmit(question);
  };

  return <Box
    title={intl.formatMessage({id: 'questionFormNumberTitle', defaultMessage: 'Question quantité'})}
    subtitle={intl.formatMessage({id: 'questionFormNumberSubtitle', defaultMessage: 'Les prospects à qui vous poserez cette question devront répondre par une quantité.{br}Le tarif en cours de calcul sera multiplié par cette quantité pour fournir une estimation.'}, {br: <br />}) as string}
    onClose={onClose}>
    <form onSubmit={emitSubmit} style={{ padding: '0 40px 40px 40px' }}>
      <QuestionCommon
        onChange={v => setQuestion({ ...question, ...(v as QuestionNumber) })}
        value={question}>
        <Input
          required
          label={intl.formatMessage({id: 'questionFormNumberUnitLabel', defaultMessage: 'Unité à utiliser pour cette question de quantité'})}
          value={question.unit}
          onChange={v => setQuestion({ ...question, unit: v })} />
      </QuestionCommon>
      <QuestionFooter onCancel={onClose} isLoading={isLoading}/>
    </form>
  </Box>;
};

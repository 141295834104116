import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PageWrapper } from '../../../components';
import { Container, useToaster } from '@linkeo.com/ui-lib-react';
import { BASE_QUESTION, useApi } from '../../../providers/api-provider';
import { Question, QuestionChoices, QuestionType, QuestionTypes } from '../../../interface/question.types';
import { QuestionForm } from '../../../components/question/question.form';
import { Choice, EditChoice } from '../../../interface/choice.types';
import { FindAndUpdateItemInArray, UpdateItemInArray, UpdateItemInObject } from '../../../utils/deep-object.utils';
import { ChoiceDialog } from '../../../components/choice/choice.dialog';
import { getQuestionByType } from '../../../utils/question.utils';
import { useIntl } from 'react-intl';
import { ServerError } from '../../../components/commons/server-error';
import { useActivityStore } from '../../../providers/activity-provider';

const baseChoice: Choice = {
  id: 'NEW',
  description: '',
  label: '',
  addChoiceQuantity: false,
  setPrice: false,
  choiceQuantityLabel: '',
  choiceQuantityUnit: '',
};

export const QuestionPage: FC = () => {
  const {
    categoryId,
    activityId,
    questionType,
  } = useParams<{ categoryId: string, activityId: string, questionType: QuestionType | string }>();
  const history = useHistory();
  const intl = useIntl();
  const toast = useToaster();
  const { saveActivity, activity, updateQuestions, questions } = useActivityStore();
  const [getQuestion, setQuestion] = useState<QuestionTypes>(BASE_QUESTION);
  const [getEditChoice, setEditChoice] = useState<Choice>();
  const [getGlobalLoading, setGlobalLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [choiceLoading, setChoiceLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const API = useApi();

  useEffect(() => {
    const newQuestion = getQuestionByType(questionType);
    if (newQuestion) {
      setQuestion(newQuestion);
      setGlobalLoading(false);
      return;
    }
    API.getQuestion(categoryId, activityId, questionType).then(result => {
      setQuestion(result);
    }).catch(() => setError(true)).finally(() => {
      setGlobalLoading(false);
    });
    return () => {
      setGlobalLoading(false);
      setQuestion(BASE_QUESTION)
    }
  }, [questionType, API, activityId, categoryId]);

  const submitQuestion = async (question: QuestionTypes) => {
    setLoading(true);
    let id = activityId;
    if (activityId.startsWith('NEW')) {
      try {
        id = (await saveActivity(true)).id;
      } catch (e) {
        console.error(e);
        toast(intl.formatMessage({
          id: 'errorServerMessage',
          defaultMessage: 'Une erreur est survenue',
        }));
        setLoading(false);
        return;
      }
    }
    if ('choices' in question) {
      const q = questions.find(el => el.id === question.id);
      if (q && 'choices' in q) {
        const deletedChoices = (q as QuestionChoices).choices.filter(c => !question.choices.some(cs => cs.id === c.id));
        try {
          deletedChoices.map(async (choice) => await API.deleteChoice(categoryId, activityId, question.id, choice.id));
        } catch (e) {
          console.error(e);
          toast(intl.formatMessage({
            id: 'errorServerMessage',
            defaultMessage: 'Une erreur est survenue',
          }));
          setLoading(false);
        }
      }
    }
    updateQuestion(id, question).then(() => {
      history.push(`/estimate/category/${categoryId}/activity/${id}`);
    }).catch(() => toast(intl.formatMessage({
      id: 'errorServerMessage',
      defaultMessage: 'Une erreur est survenue',
    }))).finally(() => setLoading(false));
  };

  const updateQuestion = async (id: string, question: Question) => {
    if (question.id.startsWith('NEW')) {
      const newQuestion = await API.postQuestion(categoryId, id, question);
      updateQuestions([...questions, newQuestion]);
      return newQuestion;
    }
    const q = await API.putQuestion(categoryId, id, question);
    const index = questions.findIndex(el => el.id === q.id);
    if (index === -1) {
      updateQuestions([...questions, q]);
    } else {
      updateQuestions(UpdateItemInArray(questions, index, q));
    }
    return q;
  };

  const deleteChoice = (value: Choice) => {
    if ('choices' in getQuestion) {
      setQuestion({
        ...getQuestion,
        choices: (getQuestion as QuestionChoices).choices.filter(el => el.id !== value.id),
      });
    }
  };

  const closeForm = () => {
    history.push(`/estimate/category/${categoryId}/activity/${activityId}`);
  };

  const addChoice = () => {
    setEditChoice(baseChoice);
  };

  const editChoice = (choice: Choice) => {
    setEditChoice(choice);
  };

  const submitChoice = async (editChoice: EditChoice) => {
    setChoiceLoading(true);
    try {
      let id = activity.id;
      if (activity.id.startsWith('NEW')) {
        id = (await saveActivity(true)).id;
      }
      const updatedQuestion = await updateQuestion(id, getQuestion);
      setQuestion(updatedQuestion);
      let tempChoice = editChoice.choice;
      const editImage = editChoice.editImage;
      let picture;
      if (editImage.isNew && editImage.image.binary) {
        picture = await API.postFile(editImage.image.binary, '/picture');
      }
      if (editImage.isNew) {
        tempChoice = {
          ...tempChoice,
          picture,
        };
      }
      if (tempChoice.id.startsWith('NEW')) {
        const choice = await API.postChoice(categoryId, id, updatedQuestion.id, tempChoice);
        setQuestion(UpdateItemInObject(getQuestion as QuestionChoices, 'choices', [
          ...(getQuestion as QuestionChoices).choices,
          choice,
        ]));
      } else {
        const choice = await API.putChoice(categoryId, id, updatedQuestion.id, tempChoice);
        setQuestion(UpdateItemInObject(getQuestion as QuestionChoices, 'choices',
          FindAndUpdateItemInArray((getQuestion as QuestionChoices).choices, 'id', choice),
        ));
      }
      history.push(`/estimate/category/${categoryId}/activity/${id}/question/${updatedQuestion.id}`);
    } catch (e) {
      toast(intl.formatMessage({ id: 'errorServerMessage', defaultMessage: 'Une erreur est survenue' }));
    } finally {
      setChoiceLoading(false);
      setEditChoice(undefined);
    }
  };

  return <PageWrapper isLoading={getGlobalLoading}>
    {!error ? <Container size={'lg'}>
      <QuestionForm
        question={getQuestion}
        setQuestion={setQuestion}
        onSubmit={submitQuestion}
        onClose={closeForm}
        onDeleteChoice={deleteChoice}
        onAddChoice={addChoice}
        onEditChoice={editChoice}
        isLoading={loading}
        loading={!!getEditChoice}
      />
      <ChoiceDialog
        choice={getEditChoice}
        loading={choiceLoading}
        onSubmit={submitChoice}
        onClose={() => setEditChoice(undefined)} />
    </Container> : <ServerError />}
  </PageWrapper>;
};
